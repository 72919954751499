<template>
  <div class="w-full">
    <v-form
      ref="linksForm"
      v-model="isFormValid"
    >
      <v-row class="ma-0 pa-0 mb-2">
        <AutomaticInlineExposure orientation="right">
          <template
            slot="field"
            slot-scope="{ events: { showTooltip, hideTooltip } }"
          >
            <CheckTextField
              v-model="linksData.website"
              :label="$t('registration.website.labels.website')"
              placeholder="https://www.max-makler.de"
              :rules="[rules.url, ...(!hasNoInitialWebsite ? [rules.required] : [])]"
              outlined
              validate-on-input
              type="url"
              hide-details="auto"
              class="mb-2"
              :disabled="hasNoInitialWebsite"
              @focus="showTooltip"
              @blur="hideTooltip"
            />
          </template>
          <template slot="tooltipContent">
            <span>{{ $t('registration.website.tooltips.website') }}</span>
          </template>
        </AutomaticInlineExposure>
      </v-row>

      <v-row
        v-if="parsingStatus === 'RUNNING'"
        class="ma-0 pa-0"
      >
        <v-alert
          outlined
          color="grey"
          class="w-full"
        >
          <v-progress-circular
            class="mr-2"
            width="3"
            size="20"
            indeterminate
          />
          <p class="mt-4">
            {{ $t('registration.website.fetching-data') }}
          </p>
        </v-alert>
      </v-row>

      <v-row
        v-if="parsingStatus === 'DONE' && parsingError"
        class="ma-0 pa-0"
      >
        <v-alert
          outlined
          type="info"
        >
          <p class="text-left">
            {{ $t('registration.website.alerts.no-data') }}
          </p>
        </v-alert>
      </v-row>

      <v-expand-transition v-if="parsingStatus === 'DONE'">
        <div>
          <v-row
            v-if="isImprintVisible"
            class="ma-0 pa-0 mb-2"
          >
            <AutomaticInlineExposure orientation="right">
              <template
                slot="field"
                slot-scope="{ events: { showTooltip, hideTooltip } }"
              >
                <CheckTextField
                  v-model="linksData.imprint"
                  :label="$t('registration.website.labels.imprint')"
                  placeholder="https://www.max-makler.de"
                  :rules="[rules.url, rules.required]"
                  outlined
                  validate-on-input
                  type="url"
                  hide-details="auto"
                  class="mb-2"
                  :disabled="hasNoInitialWebsite"
                  @focus="showTooltip"
                  @blur="hideTooltip"
                />
              </template>
              <template slot="tooltipContent">
                <span>{{ $t('registration.website.tooltips.imprint') }}</span>
              </template>
            </AutomaticInlineExposure>
          </v-row>
          <v-row
            v-if="isPrivacyVisible"
            class="ma-0 pa-0 mb-2"
          >
            <AutomaticInlineExposure orientation="right">
              <template
                slot="field"
                slot-scope="{ events: { showTooltip, hideTooltip } }"
              >
                <CheckTextField
                  v-model="linksData.privacy"
                  :label="$t('registration.website.labels.privacy')"
                  placeholder="https://www.max-makler.de"
                  :rules="[rules.url, rules.required]"
                  outlined
                  validate-on-input
                  type="url"
                  hide-details="auto"
                  class="mb-2"
                  :disabled="hasNoInitialWebsite"
                  @focus="showTooltip"
                  @blur="hideTooltip"
                />
              </template>
              <template slot="tooltipContent">
                <span>{{ $t('registration.website.tooltips.privacy') }}</span>
              </template>
            </AutomaticInlineExposure>
          </v-row>
        </div>
      </v-expand-transition>
      <v-row
        v-if="showHasNoInitialWebsite"
        class="ma-0 pa-0 mb-2 mx-2"
      >
        <AutomaticInlineExposure
          orientation="right"
          max-width="300"
        >
          <template
            slot="field"
            slot-scope="{ events: { showTooltip, hideTooltip } }"
          >
            <v-checkbox
              v-model="hasNoInitialWebsite"
              v-click-outside="hideTooltip"
              :label="$t('registration.website.labels.no-website')"
              @click="showTooltip"
            />
          </template>
          <template slot="tooltipContent">
            <span>{{ $t('registration.website.tooltips.no-website', { platform: readablePlatformName}) }}</span>
          </template>
        </AutomaticInlineExposure>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { url, required, isValidUrl } from '@/lib/validation'
import { formatHttpsUrl } from '@/lib/urlFormatter'
import PARSE_DATA_FROM_WEBSITE from '@/modules/registration/queries/ParseDataFromWebsite.gql'
import trackingEvents from '@/lib/trackingEvents'
import branding from '@/mixins/branding'
import CheckTextField from '@/components/forms/CheckTextField'
import AutomaticInlineExposure from '@/components/AutomaticInlineExposure.vue'

export default {
  components: {
    CheckTextField,
    AutomaticInlineExposure
  },
  mixins: [branding],
  props: {
    links: {
      type: Object,
      default: () => ({})
    },
    triggerValidation: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hasNoInitialWebsite: false,
      linksData: { ...this.links },
      debounceTimeout: null,

      isFormValid: false,

      parsingTimeout: null,
      parsingStatus: 'IDLE',
      parsingError: false
    }
  },
  unmounted () {
    clearTimeout(this.parsingTimeout)
    clearTimeout(this.debounceTimeout)
  },
  computed: {
    isImprintVisible () {
      return isValidUrl(this.linksData.website) && this.parsingStatus === 'DONE'
    },
    isPrivacyVisible () {
      return isValidUrl(this.linksData.website) && this.parsingStatus === 'DONE'
    },
    showHasNoInitialWebsite () {
      return !this.linksData.website || this.hasNoInitialWebsite
    }
  },

  watch: {
    links () {
      this.linksData = { ...this.links }
    },
    'linksData.website' () {
      this.update('website', this.linksData.website)
    },
    'linksData.imprint' () {
      this.update('imprint', this.linksData.imprint)
    },
    'linksData.privacy' () {
      this.update('privacy', this.linksData.privacy)
    },

    async triggerValidation () {
      if (this.triggerValidation) {
        const isFormValid = await this.$refs.linksForm.validate()
        if (isFormValid) {
          this.$emit('validated')
        }
        this.$emit('resetTriggerValidation')
      }
    },
    linksData: {
      deep: true,
      handler () {
        if (!isValidUrl(this.linksData.website)) {
          this.parsingStatus = 'IDLE'
          this.linksData.privacy = ''
          this.linksData.imprint = ''
          this.parsingError = ''
        }
      }
    },
    hasNoInitialWebsite () {
      this.$emit('updateHasNoInitialWebsite', this.hasNoInitialWebsite)
    }
  },
  created () {
    this.rules = { url, required }
  },

  methods: {
    async update (name, value) {
      if (this.links[name] === value) return
      this.linksData[name] = value
      if (name !== 'website') {
        this.$emit('updateSettings', { ...this.linksData })
      } else {
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(async () => {
          if (isValidUrl(this.linksData.website)) { // && this.links[name] !== value) {
            this.parsingStatus = 'RUNNING'
            this.parseTimeout = setTimeout(() => {
              this.parseDataFromWebsite()
            }, 4000)
          }
        }, 1000)
      }
    },

    async parseDataFromWebsite () {
      /* eslint-disable-next-line no-unused-expressions */
      this.$gtm?.trackEvent({ event: trackingEvents.WEBSITE_PARSER_STARTED })

      const url = formatHttpsUrl(this.linksData.website)
      try {
        const { data: { parseDataFromWebsite: { imprint, privacy } } } = await this.$apollo.mutate({
          mutation: PARSE_DATA_FROM_WEBSITE,
          variables: {
            input: {
              url
            }
          }
        })

        if (!imprint || !privacy) {
          this.parsingError = true
        }
        if (imprint) {
          this.linksData.imprint = imprint
        }
        if (privacy) {
          this.linksData.privacy = privacy
        }
      } catch (err) {
        this.parsingError = true
      } finally {
        this.parsingStatus = 'DONE'
        /* eslint-disable-next-line no-unused-expressions */
        this.$gtm?.trackEvent({ event: trackingEvents.WEBSITE_PARSER_FINISHED })
        this.linksData.website = url
        this.$emit('updateSettings', { ...this.linksData })
      }
    }
  }
}
</script>
